import { useState, useEffect } from 'react';
import './UserDetails.js';
import './UserList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCirclePlus, faEraser, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

function UserList(props) {
    // define states for a few things
    const [users, updateUsers] = useState('');
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
    const [newUserInfo, setNewUserInfo] = useState({
        name: '',
        fullname: '',
        email: '',
        password: ''
    });

    const updateShowAddUserModal = newValue => {
        if(newValue === false) {
            setNewUserInfo({
                name: '',
                fullname: '',
                email: '',
                password: ''
            });
        }
        setShowAddUserModal(newValue);
    }

    const updateNewUserInfo = e => {
        // force lowercase letters for the short name
        var name = e.target.name;
        var value = e.target.value;
        if(name === 'name') {
            value = e.target.value.toLowerCase();
        }
        setNewUserInfo({
            ...newUserInfo,
            [name]: value
        });
    }

    // get list of users
    const listUsers = () => {
        try {
            fetch('/api/v1/list_users', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }
            }).then(response => response.json()).then(response => {
                if(response.status === 'ok') {
                    if(response.data.length > 0) {
                        updateUsers(response.data.map(userData => {
                            return(
                                <div key={userData.id} className="user-line" data-user-id={userData.id} onClick={handleGetUserDetails}>{userData.name}</div>
                            );
                        }));
                    }
                    else {
                        console.log('No results sent from server');
                        updateUsers(<div className="user-line no">No users found</div>);
                    }
                    
                }
                else {
                    console.log('Error response from server');
                    updateUsers(<div className="user-line error">Error while listing users</div>);
                }
            });
        }
        catch(ex) {
            console.log('Exception from server: %s', ex);
            updateUsers(<div className="user-line error ex">Error (exception) while listing users</div>);
        }
    };

    const handleGetUserDetails = e => {
        // did we get a valid ID?
        if(e.target.attributes['data-user-id'] && e.target.attributes['data-user-id'].value) {
            // ask the server for the details
            try {
                fetch('/api/v1/get_user_details', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({id: e.target.attributes['data-user-id'].value})
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data) {
                            // populate response data
                            props.setUserInfoState(response.data);
                            document.querySelectorAll('.UserList .user-line').forEach(element => {
                                element.className = element.className.replace(' selected', '');
                            })
                            e.target.className += ' selected';
                        }
                        else {
                            console.log('No data returned from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                });
            }
            catch(ex) {
                console.log('Exception from server: %s', ex);
            }
        }
    }

    const addUserModal = () => {
        return(
            <div className="modal add-user">
                <button className="close-window" title="Close Window" onClick={() => updateShowAddUserModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
                <h2 className="modal-title">Add User</h2>
                <div className="row">
                    <label htmlFor="name">Name:</label>
                    <input className="name" name="name" value={newUserInfo.name} onChange={updateNewUserInfo} />
                    <div className="description">Enter the name of the user (NOTE: This cannot be changed later!)</div>
                </div>
                <div className="row">
                    <label htmlFor="fullname">Full Name:</label>
                    <input className="fullname" name="fullname" value={newUserInfo.fullname} onChange={updateNewUserInfo} />
                    <div className="description">Enter the full name of the user</div>
                </div>
                <div className="row">
                    <label htmlFor="email">Email:</label>
                    <input className="email" name="email" value={newUserInfo.email} onChange={updateNewUserInfo} />
                    <div className="description">Enter the email address of the user</div>
                </div>
                <div className="row">
                    <label htmlFor="password">Password:</label>
                    <input className="password" type="password" name="password" value={newUserInfo.password} onChange={updateNewUserInfo} />
                    <div className="description">Enter the user's desired password, or leave blank to get a random one from the server</div>
                </div>
                <div className="actions">
                    <button className="submit" title="Add User" onClick={() => handleAddUser()}><FontAwesomeIcon icon={faCheck} /></button>
                    <button className="cancel" title="Cancel" onClick={() => updateShowAddUserModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
                </div>
            </div>
        );
    }

    const removeUserModal = () => {
        return(
            <div className="modal remove-user">
            <button className="close-window" title="Close Window" onClick={() => setShowRemoveUserModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
            <h2 className="modal-title">Remove User</h2>
            <div className="row">
                <div className="warning">WARNING! You are about to remove this user, which means that all of its files and databases will be deleted. This action cannot be undone. Are you sure?</div>
            </div>
            <div className="actions">
                <button className="submit" title="Remove User" onClick={() => handleRemoveUser()}><FontAwesomeIcon icon={faCheck} /></button>
                <button className="cancel" title="Cancel" onClick={() => setShowRemoveUserModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
        </div>
        )
    }

    // handles the API call to add the user
    const handleAddUser = () => {
        // the password must be SHA-512 hashed if one was given
        crypto.subtle.digest('SHA-512', new TextEncoder().encode(newUserInfo.password)).then(buffer => {
            var userInfoRequest = {
                name: newUserInfo.name,
                fullname: newUserInfo.fullname,
                email: newUserInfo.email,
                password: ''
            }
            if(newUserInfo.password.length > 0) {
                var passwordHashArray = Array.from(new Uint8Array(buffer));
                var passwordHash = passwordHashArray.map(b => b.toString(16).padStart(2, 0)).join('');
                userInfoRequest.password = passwordHash;
            }
            
            // now try the API call
            try {
                fetch('/api/v1/add_user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(userInfoRequest)
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data) {
                            props.setUserInfoState(response.data);
                            listUsers();
                            document.querySelectorAll('.UserList .user-line').forEach(line => {
                                if(line.attributes['data-user-id'] && line.attributes['data-user-id'].value === response.data.id) {
                                    line.className += ' selected';
                                }
                            });
                            updateShowAddUserModal(false);
                        }
                        else {
                            console.log('No data returned from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                });
            }
            catch(ex) {
                console.log('Exception from server: %s', ex);
            }
        });
    }

    const handleRemoveUser = () => {
        // is there a user selected?
        if(typeof document.querySelector('.UserList .user-line.selected') === 'object' && typeof document.querySelector('.UserList .user-line.selected').attributes['data-user-id'] === 'object') {
            try {
                fetch('/api/v1/remove_user', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        id: document.querySelector('.UserList .user-line.selected').attributes['data-user-id'].value
                    })
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data) {
                            listUsers();
                            setShowRemoveUserModal(false);
                        }
                        else {
                            console.log('No data returned from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                });
            }
            catch(ex) {
                console.log('Exception from server: %s', ex);
            }
        }
        else {
            console.log('No user selected');
        }
    }

    // list users on component load
    useEffect(() => {
        listUsers();
    }, []);

    return(
        <div className="UserList">
            <h2><FontAwesomeIcon icon={faUser} /> Users</h2>
            <div className="user-list-container">
                {users}
            </div>
            <div className="actions">
                <button className="add-user" title="Add User" onClick={() => updateShowAddUserModal(true)}><FontAwesomeIcon icon={faCirclePlus} /></button>
                <button className="remove-user" title="Remove User" onClick={() => setShowRemoveUserModal(true)}><FontAwesomeIcon icon={faEraser} /></button>
            </div>
            {showAddUserModal && addUserModal()}
            {showRemoveUserModal && removeUserModal()}
        </div>
    );
}

export default UserList;