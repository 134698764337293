import './Auth.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faPersonThroughWindow, faArrowRightToBracket, faEraser } from '@fortawesome/free-solid-svg-icons';

function Auth(props) {
    // handle form reset
    const resetForm = () => {
        document.querySelectorAll('.Auth .login input').forEach((el, idx) => {
            el.value = '';
        });
    }

    // handle login button
    const checkLogin = () => {
        // need the password hash first
        crypto.subtle.digest('SHA-512', new TextEncoder().encode(document.querySelector('.Auth .login .form-fields .password').value)).then(buffer => {
            var passwordHashArray = Array.from(new Uint8Array(buffer));
            var password = passwordHashArray.map(b => b.toString(16).padStart(2, 0)).join('');
            var username = document.querySelector('.Auth .login .form-fields .username').value;
            try {
                fetch('/api/v1/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({ username, password })
                }).then(response => response.json()).then(response => {
                    if(response.status === 'error') {
                        document.querySelector('.Auth .login .login-message').innerHTML = 'Login not successful';
                        props.setIsAuthFunction(false);
                        props.setUserNameFunction('');
                    }
                    else {
                        document.querySelector('.Auth .login .login-message').innerHTML = 'Login successful';
                        props.setIsAuthFunction(true);
                        if(typeof response.data.username !== 'undefined') {
                            props.setUserNameFunction(response.data.username);
                        }
                    }
                });
            }
            catch(ex) {
                console.log(ex);
                document.querySelector('.Auth .login .login-message').innerHTML = 'Error occurred during login process';
                props.setIsAuthFunction(false);
                props.setUserNameFunction('');
            }
        });
    }

    // handle logout button
    const doLogout = () => {
        try {
            fetch('/api/v1/logout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }
            }).then(response => response.json()).then(response => {
                if(response.status === 'ok') {
                    props.setIsAuthFunction(false);
                    props.setUserNameFunction('');
                    document.querySelector('.Auth .login .login-message').innerHTML = 'Successfully logged out';
                }
                else {
                    console.log(response);
                }
            });
        }
        catch(ex) {
            console.log(ex);
        }
    }

    // handle return based on auth state
    return (
        <div className="Auth">
            {props.isAuth === false &&
            <>
            <div className="login" onSubmit={() => checkLogin()}>
                <h2>Login</h2>
                <div className="form-fields">
                    <div className="username-container">
                        <label htmlFor="username"><FontAwesomeIcon icon={faUser} /></label>
                        <input type="text" name="username" className="username" placeholder="Username" />
                    </div>
                    <div className="password-container">
                        <label htmlFor="password"><FontAwesomeIcon icon={faKey} /></label>
                        <input type="password" name="password" className="password" placeholder="Password" />
                    </div>
                    <div className="action-buttons">
                        <button type="submit" name="submit" className="submit" title="Login" onClick={() => checkLogin()}><FontAwesomeIcon icon={faArrowRightToBracket} /></button>
                        <button type="reset" name="reset" className="reset" title="Reset Form" onClick={() => resetForm()}><FontAwesomeIcon icon={faEraser} /></button>
                    </div>
                </div>
                <div className="login-message"></div>
            </div>
            </>}
            {props.isAuth === true &&
            <>
            <div className="user-info-container">
                <div className="user-info">
                    <FontAwesomeIcon icon={faUser} /> {props.userName}
                </div>
                <div className="logout">
                    <button type="submit" name="logout" title="Logout" className="logout" onClick={() => doLogout()}><FontAwesomeIcon icon={faPersonThroughWindow} /></button>
                </div>
            </div>
            </>}
        </div>
    );
}

export default Auth;