import { useState, useEffect } from 'react';
import logo from '../logo.png';
import './App.css';
import ls from 'local-storage';
import Auth from './Auth.js';
import MainWindow from './MainWindow.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

function App() {
	// handle login status - this is only for front-end purposes, as actual login session management is the responsibility of the server-side code
	const [isAuth, setIsAuth] = useState(false);
	const [userName, setUserName] = useState('');
	const [hostName, setHostName] = useState('');

	// handle the dark mode toggler button
	const darkModeToggle = () => {
		var appElement = document.querySelector('body');
		if(appElement.className.indexOf('dark-mode') >= 0) {
			appElement.className = appElement.className.replace('dark-mode', '').trim();
			ls.set('prefers-color-scheme', 'light');
			console.log('Set light mode in local storage for user');
		}
		else {
			appElement.className += ' dark-mode';
			appElement.className = appElement.className.trim();
			ls.set('prefers-color-scheme', 'dark');
			console.log('Set dark mode in local storage for user');
		}
	}

	// run an API check for a valid user session
	useEffect(() => {
		// handle dark mode on load - if there is a local storage key for it, see what that says, and if not, then go with the user's system theme
		if(ls.get('prefers-color-scheme')) {
			if(ls.get('prefers-color-scheme').indexOf('dark') >= 0) {
				console.log('User prefers dark mode (stored in local storage)');
				document.querySelector('body').className += ' dark-mode';
			}
			else {
				console.log('User prefers light mode (stored in local storage');
			}
		}
		else if(window.matchMedia('(prefers-color-scheme: dark)')) {
			console.log('User prefers dark mode (system theme)');
			document.querySelector('body').className += 'dark-mode';
		}
		else {
			console.log('User prefers light mode (system theme)');
		}

		// check for active session
		try {
			fetch('/api/v1/is_session_active', {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json;charset=utf-8'
				}
			}).then(response => response.json()).then(response => {
				// no valid session
				if(response.status === 'error') {
					setIsAuth(false);
					setUserName('');
				}

				// session is valid
				else {
					setIsAuth(true);
					setUserName(response.data.username);
				}
			});
		}
		catch(ex) {
			console.log(ex);
			setIsAuth(false);
			setUserName('');
		}

		// get hostname from server
		try {
			fetch('/api/v1/get_hostname', {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json;charset=utf-8'
				}
			}).then(response => response.json()).then(response => {
				// no valid session
				if(response.status === 'ok') {
					setHostName(response.data.hostname);
				}
			});
		}
		catch(ex) {
			console.log(ex);
		}
	}, []);

	// render code
	return (
		<div className="App">
			<header className="app-header">
				<img src={logo} className="app-logo" alt="logo" />
				<div className="program-info">
					<h1>DevAdmin-NG</h1>
					<h2>{hostName}</h2>
				</div>
				<div className="app-controls">
					<button className="app-control dark-mode-toggle" title="Toggle Dark Mode On/Off" onClick={() => darkModeToggle()}><FontAwesomeIcon icon={faMoon} /><FontAwesomeIcon icon={faSun} /></button>
				</div>
			</header>
			<Auth type="login-form" isAuth={isAuth} userName={userName} setIsAuthFunction={setIsAuth} setUserNameFunction={setUserName} />
			{isAuth && <MainWindow isAuth={isAuth} />}
			<footer>
				Copyright &copy; <a href="https://mediag.com/" target="_blank" rel="noreferrer">Media Genesis, Inc.</a> All rights reserved.
			</footer>
		</div>
	);
}

export default App;