import { useState, useEffect } from 'react';
import './ProjectDetails.js';
import './ProjectList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiagramProject, faCirclePlus, faEraser, faCheck, faSpinner, faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';

function ProjectList(props) {
    // define states for a few things
    const [projects, updateProjects] = useState('');
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);
    const [showRemoveProjectModal, setShowRemoveProjectModal] = useState(false);
    const [newProjectInfo, setNewProjectInfo] = useState({
        name: '',
        fullname: '',
        phpversion: 'latest'
    });
    const [submitButtonIcon, updateSubmitButtonIcon] = useState(<FontAwesomeIcon icon={faCheck} />);

    const updateShowAddProjectModal = newValue => {
        updateSubmitButtonIcon(<FontAwesomeIcon icon={faCheck} />);
        if(newValue === false) {
            setNewProjectInfo({
                name: '',
                fullname: '',
                phpversion: 'latest'
            });
        }
        setShowAddProjectModal(newValue);
    }

    const updateNewProjectInfo = e => {
        // force lowercase letters for the short name
        var name = e.target.name;
        var value = e.target.value;
        if(name === 'name') {
            value = e.target.value.toLowerCase();
        }
        setNewProjectInfo({
            ...newProjectInfo,
            [name]: value
        });
    }

    // get list of projects
    const listProjects = () => {
        try {
            fetch('/api/v1/list_projects', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                }
            }).then(response => response.json()).then(response => {
                if(response.status === 'ok') {
                    if(response.data.length > 0) {
                        updateProjects(response.data.map(projectData => {
                            return(
                                <div key={projectData.id} className="project-line" data-project-id={projectData.id} onClick={handleGetProjectDetails}>{projectData.name}</div>
                            );
                        }));
                    }
                    else {
                        console.log('No results sent from server');
                        updateProjects(<div className="project-line no">No projects found</div>);
                    }
                    
                }
                else {
                    console.log('Error response from server');
                    updateProjects(<div className="project-line error">Error while listing projects</div>);
                }
            });
        }
        catch(ex) {
            console.log('Exception from server: %s', ex);
            updateProjects(<div className="project-line error ex">Error (exception) while listing projects</div>);
        }
    };

    const handleGetProjectDetails = e => {
        // did we get a valid ID?
        if(e.target.attributes['data-project-id'] && e.target.attributes['data-project-id'].value) {
            // ask the server for the details
            try {
                fetch('/api/v1/get_project_details', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({id: e.target.attributes['data-project-id'].value})
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data) {
                            // populate response data
                            props.setProjectInfoState(response.data);
                            document.querySelectorAll('.ProjectList .project-line').forEach(element => {
                                element.className = element.className.replace(' selected', '');
                            })
                            e.target.className += ' selected';
                        }
                        else {
                            console.log('No data returned from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                });
            }
            catch(ex) {
                console.log('Exception from server: %s', ex);
            }
        }
    }

    const addProjectModal = () => {
        return(
            <div className="modal add-project">
                <button className="close-window" title="Close Window" onClick={() => updateShowAddProjectModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
                <h2 className="modal-title">Add Project</h2>
                <div className="row">
                    <label htmlFor="name">Name:</label>
                    <input className="name" name="name" value={newProjectInfo.name} onChange={updateNewProjectInfo} />
                    <div className="description">Enter the name of the project as it will exist in the URL and on the system (NOTE: This cannot be changed later!)</div>
                </div>
                <div className="row">
                    <label htmlFor="fullname">Full Name:</label>
                    <input className="fullname" name="fullname" value={newProjectInfo.fullname} onChange={updateNewProjectInfo} />
                    <div className="description">Enter the full name of the client/project</div>
                </div>
                <div className="row">
                    <label htmlFor="phpversion">PHP Version:</label>
                    <select className="php-versions" name="phpversion" value={newProjectInfo.phpversion} onChange={updateNewProjectInfo}>
                        {props.phpVersions}
                    </select>
                    <div className="description">Select a PHP version, or "latest" to always track the latest version</div>
                </div>
                <div className="actions">
                    <button className="submit" title="Add Project" onClick={() => handleAddProject()}>{submitButtonIcon}</button>
                    <button className="cancel" title="Cancel" onClick={() => updateShowAddProjectModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
                </div>
            </div>
        );
    }

    const removeProjectModal = () => {
        return(
            <div className="modal remove-project">
            <button className="close-window" title="Close Window" onClick={() => setShowRemoveProjectModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
            <h2 className="modal-title">Remove Project</h2>
            <div className="row">
                <div className="warning">WARNING! You are about to remove this project, which means that all of its files and databases will be deleted. This action cannot be undone. Are you sure?</div>
            </div>
            <div className="actions">
                <button className="submit" title="Remove Project" onClick={() => handleRemoveProject()}><FontAwesomeIcon icon={faCheck} /></button>
                <button className="cancel" title="Cancel" onClick={() => setShowRemoveProjectModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
            <div class="add-project-status"></div>
        </div>
        )
    }

    // handles the API call to add the project
    const handleAddProject = () => {
        // change check to spinner to signify that we are doing something
        updateSubmitButtonIcon(<FontAwesomeIcon icon={faSpinner} />);

        // now actually add the project
        try {
            fetch('/api/v1/add_project', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(newProjectInfo)
            }).then(response => response.json()).then(response => {
                if(response.status === 'ok') {
                    if(response.data) {
                        updateSubmitButtonIcon(<FontAwesomeIcon icon={faCheck} />);
                        props.setProjectInfoState(response.data);
                        listProjects();
                        document.querySelectorAll('.ProjectList .project-line').forEach(line => {
                            if(line.attributes['data-project-id'] && line.attributes['data-project-id'].value === response.data.id) {
                                line.className += ' selected';
                            }
                        });                      updateShowAddProjectModal(false);
                    }
                    else {
                        updateSubmitButtonIcon(<FontAwesomeIcon icon={faTriangleExclamation} />);
                        console.log('No data returned from server');
                        document.querySelector('.add-project-status').innerHTML = 'No data returned from server';
                    }
                    
                }
                else {
                    updateSubmitButtonIcon(<FontAwesomeIcon icon={faTriangleExclamation} />);
                    console.log('Error response from server: %s', response.message);
                    document.querySelector('.add-project-status').innerHTML = 'Error response from server: ' + response.message;
                }
            });
        }
        catch(ex) {
            updateSubmitButtonIcon(<FontAwesomeIcon icon={faTriangleExclamation} />);
            console.log('Exception from server: %s', ex);
            document.querySelector('.add-project-status').innerHTML = 'Exception from server: ' + ex.message;
        }
    }

    const handleRemoveProject = () => {
        // is there a project selected?
        if(typeof document.querySelector('.ProjectList .project-line.selected') === 'object' && typeof document.querySelector('.ProjectList .project-line.selected').attributes['data-project-id'] === 'object') {
            try {
                fetch('/api/v1/remove_project', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        id: document.querySelector('.ProjectList .project-line.selected').attributes['data-project-id'].value
                    })
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data) {
                            Object.keys(props.projectInfoState).forEach(stateKey => {
                                props.setProjectInfoState({
                                    stateKey: ''
                                });
                            });
                            listProjects();
                            setShowRemoveProjectModal(false);
                        }
                        else {
                            console.log('No data returned from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                });
            }
            catch(ex) {
                console.log('Exception from server: %s', ex);
            }
        }
        else {
            console.log('No project selected');
        }
    }

    // list projects on component load
    useEffect(() => {
        listProjects();
    }, []);

    return(
        <div className="ProjectList">
            <h2><FontAwesomeIcon icon={faDiagramProject} /> Projects</h2>
            <div className="project-list-container">
                {projects}
            </div>
            <div className="actions">
                <button className="add-project" title="Add Project" onClick={() => updateShowAddProjectModal(true)}><FontAwesomeIcon icon={faCirclePlus} /></button>
                <button className="remove-project" title="Remove Project" onClick={() => setShowRemoveProjectModal(true)}><FontAwesomeIcon icon={faEraser} /></button>
            </div>
            {showAddProjectModal && addProjectModal()}
            {showRemoveProjectModal && removeProjectModal()}
        </div>
    );
}

export default ProjectList;