import { useEffect, useState } from 'react';
import './ProjectDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCirclePlus, faEraser, faCheck, faXmark, faCopy } from '@fortawesome/free-solid-svg-icons';

function ProjectDetails(props) {
    // need state variables for various items
    const [databasesDisplay, setDatabasesDisplay] = useState('');
    const [showAddDatabaseModal, setShowAddDatabaseModal] = useState(false);
    const [showRemoveDatabaseModal, setShowRemoveDatabaseModal] = useState(false);
    const [newDatabaseInfo, setNewDatabaseInfo] = useState({
        dbname: '',
        username: '',
        password: ''
    });
    const [selectedDatabaseInfo, setSelectedDatabaseInfo] = useState({
        dbname: '',
        username: '',
        password: ''
    });
    const [showPasswordChangeInput, setShowPasswordChangeInput] = useState(false);
    const [showPhpVersionChangeInput, setShowPhpVersionChangeInput] = useState(false);

    const updateShowAddDatabaseModal = newValue => {
        if(newValue === false) {
            setNewDatabaseInfo({
                dbname: '',
                username: '',
                password: ''
            });
        }
        setShowAddDatabaseModal(newValue);
    }

    // update function for database info
    const updateNewDatabaseInfo = e => {
        setNewDatabaseInfo({
            ...newDatabaseInfo,
            [e.target.name]: e.target.value
        });
    }

    // map out the database information if we have any
    const listDatabases = () => {
        setSelectedDatabaseInfo({
            dbname: '',
            username: '',
            password: ''
        });
        if(props.projectInfoState.databases && props.projectInfoState.databases.length > 0) {
            setDatabasesDisplay(props.projectInfoState.databases.map((database, index) => {
                return(
                    <div key={index} className="database-line" data-db-id={index} onClick={handleGetDatabaseDetails}>{database.dbname}</div>
                );
            }));
        }
        else {
            setDatabasesDisplay(<div className="database-line no">(N/A)</div>);
        }
    }

    const handleGetDatabaseDetails = e => {
        // did we get a valid name?
        if(e.target.innerHTML.length > 0) {
            // we should have this information - if not, error out
            document.querySelectorAll('.ProjectDetails .database-line').forEach(element => {
                element.className = element.className.replaceAll(' selected', '');
            });
            try {
                props.projectInfoState.databases.forEach(database => {
                    // we want the exact database name, so we check if there is any bit of the string left over after removing the name
                    if(e.target.innerHTML.replaceAll(database.dbname, '').length == 0) {
                        setSelectedDatabaseInfo(database);
                        if(e.target.className.indexOf(' selected') < 0) {
                            e.target.className += ' selected';
                        }
                    }
                })
            }
            catch(ex) {
                console.log('Unable to get database information: %s', ex);
            }
        }
    }

    const addDatabaseModal = () => {
        return(
            <div className="modal add-database">
                <button className="close-window" title="Close Window" onClick={() => updateShowAddDatabaseModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
                <h2 className="modal-title">Add Database</h2>
                <div className="row">
                    <label htmlFor="dbname">Name:</label>
                    <input className="dbname" name="dbname" value={newDatabaseInfo.dbname} onChange={updateNewDatabaseInfo} />
                    <div className="description">Enter the name of the database (defaults to the name of the project)</div>
                </div>
                <div className="row">
                    <label htmlFor="username">Username:</label>
                    <input className="username" name="username" value={newDatabaseInfo.username} onChange={updateNewDatabaseInfo} />
                    <div className="description">Enter the username for the database (defaults to the name of the project)</div>
                </div>
                <div className="row">
                    <label htmlFor="password">Password:</label>
                    <input className="password" name="password" value={newDatabaseInfo.password} onChange={updateNewDatabaseInfo} />
                    <div className="description">Enter a password, or leave blank to generate a random one (or use the default, if there is one for the given username)</div>
                </div>
                <div className="actions">
                    <button className="submit" title="Add Database" onClick={() => handleAddDatabase()}><FontAwesomeIcon icon={faCheck} /></button>
                    <button className="cancel" title="Cancel" onClick={() => updateShowAddDatabaseModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
                </div>
            </div>
        );
    }

    const handleAddDatabase = () => {
        // get the project ID from the ProjectList element - easiest by far
        try {
            var id = document.querySelector('.ProjectDetails').attributes['data-id'].value;
        }
        catch(ex) {
            console.log('handleAddDatabase(): Error getting ID or password:\n%s', ex);
        }

        // make the API call
        try {
            fetch('/api/v1/add_project_database', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    id: id,
                    dbname: newDatabaseInfo.dbname,
                    username: newDatabaseInfo.username,
                    password: newDatabaseInfo.password
                })
            }).then(response => response.json()).then(response => {
                if(response.status === 'ok') {
                    if(response.data) {
                        var dbAddResponse = response;
                        try {
                            fetch('/api/v1/get_project_details', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json;charset=utf-8'
                                },
                                body: JSON.stringify({id: id})
                            }).then(response => response.json()).then(response => {
                                if(response.status === 'ok') {
                                    if(response.data) {
                                        // populate response data
                                        props.setProjectInfoState(response.data);
                                        setSelectedDatabaseInfo({
                                            dbname: dbAddResponse.data.dbname,
                                            username: dbAddResponse.data.username,
                                            password: dbAddResponse.data.password
                                        });
                                        document.querySelectorAll('.ProjectList .database-line').forEach(line => {
                                            if(line.attributes['data-db-id'] && line.attributes['data-db-id'].value === dbAddResponse.data.id) {
                                                line.className += ' selected';
                                            }
                                        });                      updateShowAddDatabaseModal(false);
                                    }
                                    else {
                                        console.log('No data returned from server');
                                    }
                                }
                                else {
                                    console.log('Error response from server');
                                }
                            });
                        }
                        catch(ex) {
                            console.log('Exception from server: %s', ex);
                        }
                    }
                    else {
                        console.log('No data returned from server');
                    }
                    
                }
                else {
                    console.log('Error response from server');
                }
            });
        }
        catch(ex) {
            console.log('Exception from server: %s', ex);
        }
    }

    const removeDatabaseModal = () => {
        return(
            <div className="modal remove-database">
            <button className="close-window" title="Close Window" onClick={() => setShowRemoveDatabaseModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
            <h2 className="modal-title">Remove Database</h2>
            <div className="row">
                <div className="warning">WARNING! You are about to remove this database from the system. This action cannot be undone. Are you sure?</div>
            </div>
            <div className="actions">
                <button className="submit" title="Remove Database" onClick={() => handleRemoveDatabase()}><FontAwesomeIcon icon={faCheck} /></button>
                <button className="cancel" title="Cancel" onClick={() => setShowRemoveDatabaseModal(false)}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
        </div>
        )
    }

    const handleRemoveDatabase = () => {
        // is there a database selected?
        if(document.querySelector('.ProjectDetails .database-line.selected').innerHTML.length > 0) {
            try {
                fetch('/api/v1/remove_project_database', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        id: document.querySelector('.ProjectDetails').attributes['data-id'].value,
                        dbname: document.querySelector('.ProjectDetails .database-line.selected').innerHTML
                    })
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data) {
                            var newDatabasesData = [];
                            props.projectInfoState.databases.forEach(database => {
                                if(database.dbname.indexOf(response.data.dbname) < 0) {
                                    newDatabasesData.push(database);
                                }
                            });
                            props.setProjectInfoState({
                                ...props.projectInfoState,
                                databases: newDatabasesData
                            });
                            listDatabases();
                            setShowRemoveDatabaseModal(false);
                        }
                        else {
                            console.log('No data returned from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                });
            }
            catch(ex) {
                console.log('Exception from server: %s', ex);
            }
        }
        else {
            console.log('No database selected');
        }
    }
    
    // password div that is wired to change to the input upon click
    const passwordDisplay = () => {
        return(<div className="password editable" onClick={() => setShowPasswordChangeInput(true)}> <span className="label">Password:</span> <span className="password-display">{props.projectInfoState.password}</span></div>);
    }

    // password change input field shown when we are changing a password
    const passwordChangeInput = () => {
        return(
            <div className="password editable">
                <label htmlFor="password">Password:</label>
                <input className="password-change" name="password" value={props.projectInfoState.password} onChange={props.updateProjectInfoState} />
                <button className="submit" title="Change Password" onClick={handleChangeProjectPassword}><FontAwesomeIcon icon={faCheck} /></button>
                <button className="cancel" title="Cancel" onClick={() => setShowPasswordChangeInput(false)}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
        );
    }

    // change project password with the server
    const handleChangeProjectPassword = e => {
        // this will bomb if we don't have a valid ID or password where expected
        try {
            var id = document.querySelector('.ProjectDetails').attributes['data-id'].value;
            var password = document.querySelector('.ProjectDetails input.password-change').value;
        }
        catch(ex) {
            console.log('handleChangeProjectPassword(): Error getting ID or password:\n%s', ex);
        }

        // make call out to server
        try {
            fetch('/api/v1/change_project_password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    id: id.replace(/[^\d]+/, ''),
                    password: password
                })
            }).then(response => response.json()).then(response => {
                if(response.status === 'ok') {
                    if(response.data) {
                        console.log('Password successfully changed');
                        setShowPasswordChangeInput(false);
                    }
                    else {
                        console.log('No data returned from server');
                    }
                    
                }
                else {
                    console.log('Error response from server');
                }
            });
        }
        catch(ex) {
            console.log('Exception from server: %s', ex);
        }
    };

    // password div that is wired to change to the input upon click
    const phpVersionDisplay = () => {
        return(<div className="phpversion editable" onClick={() => setShowPhpVersionChangeInput(true)}><span className="label">PHP Version:</span> <span className="phpversion-display">{props.projectInfoState.phpversion}</span></div>);
    }

    // password change input field shown when we are changing a password
    const phpVersionChangeInput = () => {
        return(
            <div className="phpversion editable">
                <label htmlFor="phpversion">PHP Version:</label>
                <select className="phpversion-change" name="phpversion" value={props.projectInfoState.phpversion} onChange={props.updateProjectInfoState}>
                    {props.phpVersions}
                </select>
                <button className="submit" title="Change PHP Version" onClick={handleChangeProjectPhpVersion}><FontAwesomeIcon icon={faCheck} /></button>
                <button className="cancel" title="Cancel" onClick={() => setShowPhpVersionChangeInput(false)}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
        );
    }

    // select a different PHP version
    const handleChangeProjectPhpVersion = e => {
        // this will bomb if we don't have a valid ID or password where expected
        try {
            var id = document.querySelector('.ProjectDetails').attributes['data-id'].value;
            var phpversion = document.querySelector('.ProjectDetails select.phpversion-change').value;
        }
        catch(ex) {
            console.log('handleChangeProjectPhpVersion(): Error getting ID or password:\n%s', ex);
        }

        // make call out to server
        try {
            fetch('/api/v1/change_project_php_version', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    id: id.replace(/[^\d]+/, ''),
                    phpversion: phpversion
                })
            }).then(response => response.json()).then(response => {
                if(response.status === 'ok') {
                    if(response.data) {
                        console.log('PHP version successfully changed');
                        setShowPhpVersionChangeInput(false);
                    }
                    else {
                        console.log('No data returned from server');
                    }
                    
                }
                else {
                    console.log('Error response from server');
                }
            });
        }
        catch(ex) {
            console.log('Exception from server: %s', ex);
        }
    };

    const copyDetails = () => {
        if(typeof props.projectInfoState.id === 'number') {
            var details = 'URL: ' + props.projectInfoState.url + '\n';
            details += 'Login: ' + props.projectInfoState.login + '\n';
            details += 'Password: ' + props.projectInfoState.password + '\n';
            navigator.clipboard.writeText(details).then(result => {
                alert('Details copied to clipboard');
            });
        }
        else {
            alert('No project selected!');
        }
    }

    // load project details on render if we have valid project data
    useEffect(() => {
        if(typeof props.projectInfoState.id === 'number') {
            console.log('Project ID "%s"', props.projectInfoState.id);
            console.log('Databases: "%s"', props.projectInfoState.databases);
            listDatabases();
        }
    }, [props.projectInfoState.id, props.projectInfoState.databases]);

    return(
        <div className="ProjectDetails" data-id={props.projectInfoState.id}>
            <h2 className="project-name"><FontAwesomeIcon icon={faCircleInfo} /> {props.projectInfoState.name} ({props.projectInfoState.fullname})</h2>
            <div className="info-container">
                <div className="overview-pane">
                    <h3>Overview</h3>
                    <div className="overview">
                        <div className="url"><span className="label">URL:</span> <a href={props.projectInfoState.url} target="_blank" rel="noreferrer">{props.projectInfoState.url}</a></div>
                        <div className="login"><span className="label">Login:</span> {props.projectInfoState.login}</div>
                        {showPasswordChangeInput ? passwordChangeInput() : passwordDisplay()}
                        {showPhpVersionChangeInput ? phpVersionChangeInput() : phpVersionDisplay()}
                        <button className="copy-details" title="Copy Details" onClick={() => copyDetails()}><FontAwesomeIcon icon={faCopy} /></button>
                    </div>
                </div>
                <div className="database-pane">
                    <h3>Associated Databases</h3>
                    <div className="project-database-container">
                        {databasesDisplay}
                    </div>
                    <div className="actions">
                        <button className="add-database" title="Add Database" onClick={() => updateShowAddDatabaseModal(true)}><FontAwesomeIcon icon={faCirclePlus} /></button>
                        <button className="remove-database" title="Remove Database" onClick={() => setShowRemoveDatabaseModal(true)}><FontAwesomeIcon icon={faEraser} /></button>
                    </div>
                    <div className="database-information">
                        <div className="database-name"><span className="label">DB Name:</span> {selectedDatabaseInfo.dbname}</div>
                        <div className="database-user"><span className="label">Username:</span> {selectedDatabaseInfo.username}</div>
                        <div className="database-password"><span className="label">Password:</span> {selectedDatabaseInfo.password}</div>
                    </div>
                </div>
            </div>
            {showAddDatabaseModal && addDatabaseModal()}
            {showRemoveDatabaseModal && removeDatabaseModal()}
        </div>
    );
}

export default ProjectDetails;