import { useState } from 'react';
import './UserDetails.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faCirclePlus, faEraser, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

function UserDetails(props) {
    const [showPasswordChangeInput, setShowPasswordChangeInput] = useState(false);

    // password div that is wired to change to the input upon click
    const passwordDisplay = () => {
        return(<div className="password editable" onClick={() => setShowPasswordChangeInput(true)}><span className="label">Password:</span> <span className="password-display">********</span></div>);
    }

    // password change input field shown when we are changing a password
    const passwordChangeInput = () => {
        return(
            <div className="password editable">
                <label htmlFor="password">Password:</label>
                <input className="password-change" name="password" type="password" value={props.userInfoState.password} onChange={props.updateUserInfoState} />
                <button className="submit" title="Change Password" onClick={handleChangeUserPassword}><FontAwesomeIcon icon={faCheck} /></button>
                <button className="cancel" title="Cancel" onClick={() => setShowPasswordChangeInput(false)}><FontAwesomeIcon icon={faXmark} /></button>
            </div>
        );
    }

    // change user password with the server
    const handleChangeUserPassword = e => {
        try {
            var id = document.querySelector('.UserDetails').attributes['data-id'].value;
            var password = document.querySelector('.UserDetails input.password-change').value;
        }
        catch(ex) {
            console.log('handleChangeUserPassword(): Error getting ID or password:\n%s', ex);
        }

        // hash the password and make the request
        crypto.subtle.digest('SHA-512', new TextEncoder().encode(document.querySelector('.UserDetails input.password-change').value)).then(buffer => {
            var passwordHashArray = Array.from(new Uint8Array(buffer));
            var changePasswordRequest = {
                id: id,
                password: passwordHashArray.map(b => b.toString(16).padStart(2, 0)).join('')
            }

            // make call out to server
            try {
                fetch('/api/v1/change_user_password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(changePasswordRequest)
                }).then(response => response.json()).then(response => {
                    if(response.status === 'ok') {
                        if(response.data) {
                            console.log('Password successfully changed');
                            setShowPasswordChangeInput(false);
                        }
                        else {
                            console.log('No data returned from server');
                        }
                        
                    }
                    else {
                        console.log('Error response from server');
                    }
                });
            }
            catch(ex) {
                console.log('Exception from server: %s', ex);
            }
        });
    };

    return(
        <div className="UserDetails" data-id={props.userInfoState.id}>
            <h2 className="user-name"><FontAwesomeIcon icon={faCircleInfo} /> {props.userInfoState.name} ({props.userInfoState.fullname})</h2>
            <div className="info-container">
                <h3>Overview</h3>
                <div className="overview">
                    <div className="fullname"><span className="label">Full Name:</span> {props.userInfoState.fullname}</div>
                    <div className="email"><span className="label">Email:</span> {props.userInfoState.email}</div>
                    {showPasswordChangeInput ? passwordChangeInput() : passwordDisplay()}
                </div>
            </div>
        </div>
    );
}

export default UserDetails;